<template>
  <v-container fluid>
    <v-card class="pa-4">
      <h3>Informe {{ idEvento }}</h3>
			<div class="d-flex my-2">
				<v-spacer></v-spacer>
				<v-btn @click.stop="addCategory" color="primary">
					<v-icon left>mdi-plus-thick</v-icon>
					Añadir categoría
				</v-btn>
			</div>
			<v-row class="ma-2">
				<v-col cols="12" md="6">
					<v-list nav>
						<v-list-item-group mandatory v-model="activeCat">
							<v-list-item :value="index" v-for="(cat, index) in informe.categorias" :key="index" >
								<v-list-item-icon>
									<v-icon>mdi-folder</v-icon>
								</v-list-item-icon>
								<v-text-field filled dense hide-details v-model="cat.name"></v-text-field>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-col>
				<v-divider vertical></v-divider>
				<v-col cols="12" md="6">
					<v-list nav>
						<v-list-item-group>
							<v-list-item v-for="(item, index) in items" :key="index" >
								<v-list-item-icon>
									<v-icon>mdi-folder</v-icon>
								</v-list-item-icon>
								<v-text-field filled dense hide-details v-model="item.name"></v-text-field>
							</v-list-item>
						</v-list-item-group>
					</v-list>
					<div v-if="items" class="d-flex">
						<v-spacer></v-spacer>
						<v-btn @click.stop="addItem" fab color="primary">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</div>
				</v-col>
			</v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      idEvento: this.$route.params.id,
      informe: {
        categorias: [],
      },
			activeCat: 0,
    };
  },
	computed: {
		items() {
			if (this.informe.categorias[ this.activeCat ])
				return this.informe.categorias[ this.activeCat ].items
			return null
		}
	},
  methods: {
    addCategory() {
      this.informe.categorias.push({
        name: "",
        items: [],
      });
    },
    addItem() {
      this.informe.categorias[ this.activeCat ].items.push({
        name: "",
        price: 0,
				qty: 1
      });
    },
  },
};
</script>

<style>
</style>